// components/CarSearchForm.tsx

'use client';

import React, { useState } from 'react';
import { useRouter } from 'next/navigation';
import ModalSelectCar, { CarData } from './ModalSelectCar';

export default function CarSearchForm() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [selectedCar, setSelectedCar] = useState<CarData | null>(null);
  const router = useRouter();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    setIsModalOpen(true);
  };

  const handleCarSelect = (car: CarData) => {
    setIsModalOpen(false);
    setInputValue(`${car.car_brand} ${car.car_model} ${car.car_year_from}-${car.car_year_to}`);
    setSelectedCar(car);
  };

  const handleConsultPrice = () => {
    if (selectedCar) {
      router.push(`/bateria?carId=${selectedCar.id}`);
    } else {
      alert('Por favor, selecione um modelo de carro primeiro.');
    }
  };

  return (
    <>
      <div className="domain-search domain-search-two">
        <form>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="Digite o modelo do carro para consultar o preço"
              value={inputValue}
              onChange={handleInputChange}
            />
            <button
              type="button"
              className="btn btn-gradient"
              onClick={handleConsultPrice}
            >
              Consultar Preço
            </button>
          </div>
        </form>
      </div>

      {/* Modal de Seleção de Carro */}
      {isModalOpen && (
        <ModalSelectCar
          onSelect={handleCarSelect}
          onClose={() => setIsModalOpen(false)}
          initialSearchTerm={inputValue}
        />
      )}
    </>
  );
}
