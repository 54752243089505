// components/ModalSelectCar.tsx

'use client';

import React, { useState, useEffect, useMemo } from 'react';
import carDataJson from '../data/carData.json';

export interface CarData {
  battery_alt?: string;
  battery: string;
  car_brand: string;
  car_model: string;
  car_year_from: number | string;
  car_year_to: number | string;
  car: string;
  complex: boolean;
  id: string;
  vehicle_type: string;
  amper: number | string;
  image_url: string;
  count: number | string;
  automaker_slug: string;
  'Preço M'?: number | string;
  'Preço Z'?: number | string;
}

interface ModalSelectCarProps {
  onSelect: (car: CarData) => void;
  onClose: () => void;
  initialSearchTerm: string;
}

const carData: CarData[] = carDataJson['Sheet 1'] as unknown as CarData[];

export default function ModalSelectCar({
  onSelect,
  onClose,
  initialSearchTerm,
}: ModalSelectCarProps) {
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);

  useEffect(() => {
    setSearchTerm(initialSearchTerm);
  }, [initialSearchTerm]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 300); // Debounce time

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  const filteredCars = useMemo(() => {
    if (!debouncedSearchTerm) {
      return [];
    }

    const terms = debouncedSearchTerm.toLowerCase().split(' ');
    return carData
      .filter((car) => {
        const carName = `${car.car_brand} ${car.car_model}`.toLowerCase();
        const yearRange = `${car.car_year_from}-${car.car_year_to}`;

        return terms.every((term) => carName.includes(term) || yearRange.includes(term));
      })
      .slice(0, 10);
  }, [debouncedSearchTerm]);

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="btn-close" onClick={onClose}>
          &times;
        </button>
        <h3 className="modal-title">Selecione o modelo do seu carro</h3>
        <input
          type="text"
          className="modal-search-input"
          placeholder="Buscar modelo"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          autoFocus
        />
        <ul className="car-list">
          {filteredCars.length > 0 ? (
            filteredCars.map((car) => (
              <li key={car.id} className="car-item" onClick={() => onSelect(car)}>
                <img
                  src={`/assets/images/brands/${car.automaker_slug}.webp`}
                  alt={car.car_brand}
                  className="brand-logo"
                />
                <div className="car-info">
                  <span className="car-name">{car.car_brand}</span>
                  <span className="car-subtext">{car.car_model}</span>
                  <span className="car-year">
                    {car.car_year_from}-{car.car_year_to}
                  </span>
                </div>
              </li>
            ))
          ) : (
            <li className="no-results">Nenhum modelo encontrado.</li>
          )}
        </ul>
      </div>
    </div>
  );
}
